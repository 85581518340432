
import ZoomIn from "./ZoomIn";
import ZoomOut from "./ZoomOut";
import ZoomReset from "./ZoomReset";
import InfoButton from "./InfoButton";

const ZoomControls = (props) => (
	<div className="zoom-controls">
	    <ZoomIn clickAction={props.zoomIn}/>
        <ZoomOut clickAction={props.zoomOut}/>
        <ZoomReset  clickAction={props.reset} />
        <InfoButton  setShowInfo={props.setShowInfo} showInfo={props.showInfo} />
	</div>
)

export default ZoomControls;