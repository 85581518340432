import * as d3 from "d3";
import { useState } from "react";
import * as topojson from "topojson";
import { kebabCase } from "./kebabCase";

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/choropleth
export const choroplethRegions = (
  data,
  {
    id = (d) => d.id, // given d in data, returns the feature id
    value = () => undefined, // given d in data, returns the quantitative value
    title, // given a feature f and possibly a datum d, returns the hover text
    format, // optional format specifier for the title
    scale = d3.scaleSequential, // type of color scale
    // scale, // type of color scale
    domain, // [min, max] values; input of color scale
    cityDomain, // [min, max] values; input of color scale
    range = d3.interpolateBlues, // output of color scale
    width = 640, // outer width, in pixels
    height, // outer height, in pixels
    projection, // a D3 projection; null for pre-projected geometry
    features, // a GeoJSON feature collection
    features2, // a GeoJSON feature collection
    featureId = (d) => String(d.properties.EER13CDO), // given a feature, returns its id
    regionNames = (d) => String(d.properties.EER13NM), // given a feature, returns its id
    countries,
    regions,
    borders, // a GeoJSON object for stroking borders - countries
    borders2, // a GeoJSON object for stroking borders - regions
    borders3, // a GeoJSON object for stroking borders - ireland
    outline = projection && projection.rotate ? { type: "Sphere" } : null, // a GeoJSON object for the background
    unknown = "#666", // fill color for missing data
    fill = "transparent", // fill color for outline
    stroke = "#29182d", // stroke color for borders
    // stroke = "white", // stroke color for borders
    strokeLinecap = "round", // stroke line cap for borders
    strokeLinejoin = "round", // stroke line join for borders
    strokeWidth, // stroke width for borders
    strokeOpacity, // stroke opacity for borders
    cities,
    question,
  } = {},
  loaded,
  dataType,
  navOpen,
  // setCurrentLocation,
  // currentLocation
) => {
  // Compute values.
  const N = d3.map(data, id);
  const V = d3.map(data, value).map((d) => (d == null ? NaN : +d));
  const Im = new d3.InternMap(N.map((id, i) => [id, i]));
  const If = d3.map(features.features, featureId); // return array of feature IDs
  // const If = d3.map(regions.features, regionNames); // return array of feature IDs
  
  const regionName = d3.map(regions.features, regionNames);



  let titleString = "";

  // Compute default domains.
  if (domain === undefined) domain = d3.extent(V);
  if (cityDomain === undefined) cityDomain = d3.extent(V);

  // Construct scales.
  const color = scale(domain, range);
  if (color.unknown && unknown !== undefined) color.unknown(unknown);
  const cityColor = scale(cityDomain, range);
  if (cityColor.unknown && unknown !== undefined) cityColor.unknown(unknown);

  // Compute titles.
  if (title === undefined) {
    format = color.tickFormat(100, format);
    title = (f, i) => {
      return `${f.properties.NAME_2}\n${format(V[i])}`;
    };
  } else if (title !== null) {
    const T = title;
    const O = d3.map(data, (d) => d);
    title = (f, i) => {
      titleString = T(f, O[i]);
      T(f, O[i]);
    };
  }

  // Compute the default height. If an outline object is specified, scale the projection to fit
  // the width, and then compute the corresponding height.

  if (height === undefined) {
    if (outline === undefined) {
      height = 400;
    } else {
      const [[x0, y0], [x1, y1]] = d3.geoPath(projection.fitWidth(width, outline)).bounds(outline);
      const dy = Math.ceil(y1 - y0),
      l = Math.min(Math.ceil(x1 - x0), dy);
      projection
      .center([0, 55.4])
      .rotate([4.4, 0])
      .parallels([50, 0])
      .scale(100)
      .translate([width / 2, height / 2]);
      height = dy;
      console.log(height)
    }
  }

  let prevFillColours = [];

  // Construct a path generator.
  const path = d3.geoPath(projection);

  const circleR = 7 * (height/1000);
  const svgRegions = d3
  .create("svg")
  .attr("xmlns", "http://www.w3.org/2000/svg")
  .attr("class", "mapRegions")
  .attr("width", width)
  .attr("height", height)
  .attr("viewBox", [0, 0, width, height])
  .attr("style", "width: 100%;height: 100%; height: intrinsic;fill: grey;transform-origin: center;");
  
  const tooltip = d3.select("#tooltip");
  
  const topg = svgRegions.append("g").attr("class", "hover");

  

  // Regions styles

  topg
  .selectAll("path")
  .data(regions.features)
  .join("path")
  .attr("fill", (d, i) => {
    prevFillColours.push(color(V[Im.get(If[i])]));
    return color(V[Im.get(If[i])]);
  })
  // .attr("stroke", "white")
  .attr("stroke", "#29182d")
  .attr("stroke-width", "1")
  .attr("z-index", "1")
  .style("cursor", "pointer")
  .attr("class", "region")
  .attr("id", (d, i) => kebabCase(d.properties.EER13NM))
  .attr("d", path)
  .on("mouseover", function (event) {

    // setCurrentLocation(d3.select(this).text())
      // d3.select(this).style("fill", "#331f37").style("transition", "fill .2s ease");
      d3.select(this).style("fill", "#ff455d").style("transition", "fill .2s ease");
      tooltip
        .style("visibility", "visible")
        .style("background-color", "white")
        .style("display", "block")
        .style("position", "absolute")
        .style("width", "120px")
        .style("text-align", "center")
        .style("height", "auto")
        // .style("transform", "translateX(96%)")
        .style("transform", "translateX(-55%)")
        .style("transform-origin", "bottom center")
        .style("padding", "10px 20px")
        .style("border-radius", "4px")
        // .style("border", "2px solid #ff455d")
        // .style("border", "2px solid #ff455d")
        // .style("-webkit-box-shadow", "0px 0px 11px 1px rgba(0,0,0,0.2)")
        // .style("-moz-box-shadow", "0px 0px 11px 1px rgba(0,0,0,0.2)")
        // .style("box-shadow", "0px 0px 11px 1px rgba(0,0,0,0.2)")
        .html(d3.select(this).text());

          // tooltip.style("transform", `translateX(calc(-55%))`)
   
    })
    .on("mousemove", function (event) {
      tooltip.style("top", event.offsetY + 30 + "px").style("left", event.offsetX + 15 + "px");
    })
    .on("mouseout", function () {
      d3.select(this)
        .style("fill", (d, i) => prevFillColours[d.properties.EER13CDO - 1])
        .style("transition", "fill .2s ease");
      tooltip.style("visibility", "hidden");
    })



    
    .text((d, i) => {
      if (typeof title(d, Im.get(If[i])) !== "string") {
        return titleString;
        
      }
    });



  if (borders != null)
    svgRegions
      .append("path")
      .attr("pointer-events", "none")
      .attr("fill", "none")
      .attr("stroke", stroke)
      .attr("stroke-linecap", strokeLinecap)
      .attr("stroke-linejoin", strokeLinejoin)
      .attr("stroke-width", strokeWidth * (height/1700))
      .attr("stroke-opacity", strokeOpacity)
      .attr("d", path(borders));

  if (borders2 != null)
    svgRegions
      .append("path")
      .attr("pointer-events", "none")
      .attr("fill", "none")
      .attr("stroke", stroke)
      .attr("stroke-linecap", strokeLinecap)
      .attr("stroke-linejoin", strokeLinejoin)
      .attr("stroke-width", strokeWidth * (height/1700))
      .attr("stroke-opacity", strokeOpacity)
      .attr("d", path(borders2));

  if (borders3 != null)
    svgRegions
      .append("path")
      .attr("pointer-events", "none")
      .attr("fill", "none")
      .attr("stroke", stroke)
      .attr("stroke-linecap", strokeLinecap)
      .attr("stroke-linejoin", strokeLinejoin)
      .attr("stroke-width", 0)
      .attr("stroke-opacity", strokeOpacity)
      .attr("d", path(borders3));

  svgRegions.append("g").selectAll("path").data(features2.features).join("path").attr("fill", "#aaaaaa44").attr("d", path);

  // City styles

  svgRegions
    .append("g")
    .selectAll("circle")
    .data(cities)
    .enter()
    .append("circle")
    .attr("cx", function (d) {
      return projection([d.Long, d.Lat])[0];
    })
    .attr("cy", function (d) {
      return projection([d.Long, d.Lat])[1];
    })
    .attr("r", circleR)
    // .style("stroke", "#282928")
    .style("stroke", "white")
    .style("z-index", "100")
    .style("cursor", "pointer")
    .attr("stroke-width", 10 * (height/1700))
    .attr("fill", (d, i) => {
      prevFillColours.push(color(d[`Q${question}`]));
      return color(d[`Q${question}`]);
    })


  svgRegions
    .append("g")
    .selectAll("circle")
    .data(cities)
    .enter()
    .append("circle")
    .attr("cx", function (d) {
      return projection([d.Long, d.Lat])[0];
    })
    .attr("cy", function (d) {
      return projection([d.Long, d.Lat])[1];
    })
    .attr("r", circleR)
    .style("stroke", "#282928")
    // .style("stroke", "white")
    .style("z-index", "100")
    .style("cursor", "pointer")
    .attr("stroke-width", 3 * (height/1700))
    .attr("fill", (d, i) => {
      prevFillColours.push(color(d[`Q${question}`]));
      return color(d[`Q${question}`]);
    })
    // .attr("filter", "drop-shadow(-1.5px -1.5px 0px #ffffff) drop-shadow(1.5px -1.5px 0px #ffffff) drop-shadow(-1.5px 1.5px 0px #ffffff) drop-shadow(1.5px 1px 0px #ffffff)")
    .on("mouseover", function (event) {
      // setCurrentLocation(d3.select(this).text())
      // d3.select(this).style("fill", "#331f37").style("transition", "fill .2s ease");
      d3.select(this).style("fill", "#ff455d").style("transition", "fill .2s ease");
      tooltip
      .style("visibility", "visible")
      .style("background-color", "white")
      .style("display", "block")
      .style("position", "absolute")
      .style("width", "120px")
      .style("text-align", "center")
      .style("height", "auto")
      // .style("transform", "translateX(96%)")
      .style("transform", `translateX(-55%)`)
      .style("transform-origin", "bottom")
      .style("padding", "10px 20px")
      .style("border-radius", "4px")
      .style("border", "2px solid #ff455d")
      .style("-webkit-box-shadow", "0px 0px 11px 1px rgba(0,0,0,0.2)")
      .style("-moz-box-shadow", "0px 0px 11px 1px rgba(0,0,0,0.2)")
      .style("box-shadow", "0px 0px 11px 1px rgba(0,0,0,0.2)")
      .html(d3.select(this).text());
    })
    .on("mousemove", function (event) {
      tooltip.style("top", event.offsetY + 30 + "px").style("left", event.offsetX + 15 + "px");
    })
    .on("mouseout", function () {
      d3.select(this)
        .style("fill", (d, i) => color(d[`Q${question}`]))
        .style("transition", "fill .2s ease");
      tooltip.style("visibility", "hidden");
    })
    .text((d, i) => {
      if (question == "11b" || question == "12a" || question == "18a")
        return `
        <div style="font-family: 'PhoenixSans-Medium',sans-serif;font-size: 1.2rem; line-height:1.6rem; color:#ff455d; margin-top:0.4rem;">${d["City"]}</div>
        <div style="font-family: 'PhoenixSans-Bold',sans-serif;font-size: 1.6rem; line-height:2rem; color:#331f37;">${Number(d["Q" + question]).toFixed(1)}</div>
        `;
      else if ( question == "14c")
      return `
      <div style="font-family: 'PhoenixSans-Medium',sans-serif;font-size: 1.2rem; line-height:1.6rem; color:#ff455d; margin-top:0.4rem;">${d["City"]}</div>
      <div style="font-family: 'PhoenixSans-Bold',sans-serif;font-size: 1.6rem; line-height:2rem; color:#331f37;">£${Number(d["Q" + question]).toFixed(1)}k</div>
      `;
      else if ( question == "16a" || question == "17b")
      return `
      <div style="font-family: 'PhoenixSans-Medium',sans-serif;font-size: 1.2rem; line-height:1.6rem; color:#ff455d; margin-top:0.4rem;">${d["City"]}</div>
      <div style="font-family: 'PhoenixSans-Bold',sans-serif;font-size: 1.6rem; line-height:2rem; color:#331f37;">£${Number(d["Q" + question]).toFixed(2)}</div>
      `;
    else
 
        return `<div style="font-family: 'PhoenixSans-Medium',sans-serif;font-size: 1.2rem; line-height:1.6rem; color:#ff455d; margin-top:0.4rem;">${d["City"]}</div>
        <div style="font-family: 'PhoenixSans-Bold',sans-serif;font-size: 1.6rem; line-height:2rem; color:#331f37;">${d["Q" + question]}%</div>`;
    });

  // svgRegions.style("filter", "drop-shadow(0 0 0.55rem #331e3722)");
  // svgRegions.style("stroke", "#29182d");
  // svgRegions.style("stroke-width", "0");

  return Object.assign(svgRegions.node(), { scales: { color } });
};

