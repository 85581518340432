const InfoButton = (props) => (
  <div className={props.showInfo ? `info-button active` : `info-button`}>
    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46" onClick={() => props.setShowInfo(!props.showInfo)}>
      <rect className="cls-2" width="46" height="46" rx="5" ry="5" />
      <path
        className="cls-1"
        d="m12.56,36.42c-.82,0-1.52-.29-2.11-.88-.58-.58-.88-1.29-.88-2.11v-5.96h2.98v5.96h5.96v2.98h-5.96Zm14.91,0v-2.98h5.96v-5.96h2.98v5.96c0,.82-.29,1.52-.88,2.11-.58.58-1.29.88-2.11.88h-5.96Zm-4.47-7.45c-1.64,0-3.04-.58-4.21-1.75-1.17-1.17-1.75-2.57-1.75-4.21s.58-3.04,1.75-4.21,2.57-1.75,4.21-1.75,3.04.58,4.21,1.75c1.17,1.17,1.75,2.57,1.75,4.21s-.58,3.04-1.75,4.21-2.57,1.75-4.21,1.75Zm0-2.98c.82,0,1.52-.29,2.11-.88.58-.58.88-1.28.88-2.1s-.29-1.52-.88-2.11c-.58-.58-1.29-.88-2.11-.88s-1.52.29-2.1.88c-.58.58-.88,1.29-.88,2.11s.29,1.52.88,2.1c.58.58,1.29.88,2.1.88Zm-13.42-7.45v-5.96c0-.82.29-1.52.88-2.11.58-.58,1.29-.88,2.11-.88h5.96v2.98h-5.96v5.96h-2.98Zm23.85,0v-5.96h-5.96v-2.98h5.96c.82,0,1.52.29,2.11.88.58.58.88,1.29.88,2.11v5.96h-2.98Z"
      />
    </svg> */}

    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46" onClick={() => props.setShowInfo(!props.showInfo)}>
      <rect className="cls-2" width="46" height="46" rx="4" ry="4" />
      <path
        className="cls-1"
        d="m33.71,12.3c-5.91-5.92-15.49-5.92-21.41-.01-5.92,5.91-5.92,15.49-.01,21.41,5.91,5.92,15.49,5.92,21.41.01,5.92-5.91,5.92-15.49.01-21.41Zm-8.6,20.3c0,.23-.19.42-.42.42h-3.38c-.23,0-.42-.19-.42-.42v-12.55c0-.23.19-.42.42-.42h3.38c.23,0,.42.19.42.42v12.55Zm-2.11-14.73c-1.35,0-2.45-1.1-2.45-2.45s1.1-2.45,2.45-2.45,2.45,1.1,2.45,2.45c0,1.35-1.1,2.45-2.45,2.45Z"
      />
    </svg>
  </div>
);

export default InfoButton;
