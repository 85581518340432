import React, { Fragment, useEffect, useState, useRef, forwardRef, createRef } from "react";
import { useParams } from "react-router-dom";
import mainLogo from "./images/phoenix-white-logo.svg";
import darkLogo from "./images/phoenix-dark-logo.svg";
import ZoomControls from "./components/gui/zoom/ZoomControls";
import fullMapCountries from "./data/Countries.json";
import irelandMap from "./data/ireland.json";
import fullMapRegions from "./data/uk.json";
import * as topojson from "topojson";
import * as d3 from "d3";
import { getData } from "./util/getData";
import { getQuestionText } from "./util/getQuestionText";
import { getCityData } from "./util/getCityData";
import { choroplethRegions } from "./util/choroplethRegions";
import Spinner from "./components/gui/Spinner/Spinner";

const MapUK = () => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [questionText, setQuestionText] = useState([]);
  const [countriesMap, setCountriesMap] = useState(null);
  const [regions, setRegions] = useState(null);
  const [countries, setCountries] = useState(null);
  const [ireland, setIreland] = useState(null);
  const [regionsMesh, setRegionsMesh] = useState(null);
  const [countriesMesh, setCountriesMesh] = useState(null);
  const [map, setMap] = useState(null);
  const [mapCountries, setMapCountries] = useState(null);
  const [mapRegions, setMapRegions] = useState(null);
  const [question, setQuestion] = useState("1a");
  const [zoomLevel, setZoomLevel] = useState(1);
  const [centroid, setCentroid] = useState(null);
  const [regionBoxLoaded, setRegionBoxLoaded] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  // const [currentLocation, setCurrentLocation] = useState(null);
  const [dataHighLow, setDataHighLow] = useState([]);

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  function handleWindowSizeChange() {
    setViewportWidth(window.innerWidth);
    setViewportHeight(window.innerHeight);
  }

  let { id } = useParams();

  useEffect(() => {
    // get answers for question so highest and lowest answers can be calculated and passed to the colour range
    const answers = [];
    const tempHighLow = [];

    data.map((region) => {
      answers.push(region[`Q${question}`]);
    });

    cityData.map((city) => {
      answers.push(city[`Q${question}`]);
    });

    tempHighLow.push(Math.min(...answers));
    tempHighLow.push(Math.max(...answers));

    setDataHighLow(tempHighLow);
  }, [data, cityData, question]);

  useEffect(() => {
    const tooltip = d3.select("#tooltip").append("div").style("position", "absolute").style("visibility", "visible");

    if (!navOpen) {
      // const tooltip = d3.select("#tooltip");

      tooltip.style("marginLeft", "-250px");
    }
  });

  useEffect(() => {
    // setLoaded(false);
    const sidepanelQuestions = [...document.getElementsByClassName("sidepanel-question-heading")];
    sidepanelQuestions.forEach((q, index) => {
      if (index == 0) q.classList.add("active");
      q.addEventListener("click", (e) => {
        sidepanelQuestions.forEach((el, index) => {
          el.classList.remove("active");
        });
        e.target.classList.add("active");
      });
    });

    const showHidepanels = setTimeout(() => {
      if (viewportWidth < 768) {
        if (showInfo && navOpen) {
          setNavOpen(true);
          setShowInfo(false);
        }

        // if (showInfo && !navOpen) {

        // }
      } else {
        setNavOpen(true);
        setShowInfo(true);
      }
    }, 1000);

    window.addEventListener("resize", handleWindowSizeChange);
    center();
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    getData(setData);
    getCityData(setCityData);
    getQuestionText(setQuestionText);

    setRegions(topojson.feature(fullMapRegions, fullMapRegions.objects.eer));
    setCountries(topojson.feature(fullMapCountries, fullMapCountries.objects.Countries));
    setIreland(topojson.feature(irelandMap, irelandMap.objects.IRL_adm1));
    setRegionsMesh(topojson.mesh(fullMapRegions, fullMapRegions.objects.eer, (a, b) => a !== b));
    setCountriesMesh(topojson.mesh(fullMapCountries, fullMapCountries.objects.Countries, (a, b) => a !== b));
  }, [loaded, question]);

  const svgRegions = useRef(null);

  useEffect(() => {
    if (data.length > 0) setCountriesMap(new Map(countries.features.map((d) => [d.id, d])));

    if (data.length > 0 && mapRegions && svgRegions.current) {
      if (svgRegions.current.hasChildNodes()) {
        svgRegions.current.removeChild(svgRegions.current.firstChild);
      }
      svgRegions.current.appendChild(mapRegions);
    }

    setLoaded(true);
  }, [data, map, dataHighLow, navOpen, questionText, cityData, mapRegions]);

  const UKBox = d3.select(`svg.mapRegions`)?.node()?.getBBox();

  const margin = 500;

  let worldTopLeft = null;
  let worldBottomRight = null;

  worldTopLeft = [UKBox?.x - margin, UKBox?.y - margin];
  worldBottomRight = [UKBox?.x + UKBox?.width + margin, UKBox?.y + UKBox?.height + margin];

  // ZOOM
  let zoom = d3.zoom().translateExtent([worldTopLeft, worldBottomRight]).scaleExtent([1, 3]).on("zoom", handleZoom);

  function initZoom() {
    d3.select("svg.mapRegions")
      .call(zoom)
      // .call(zoom.scaleTo, zoomLevel)
      .call(zoom.translateTo, 0.5 * viewportWidth, 0.5 * viewportHeight);
  }

  function handleZoom(e) {
    d3.selectAll("svg.mapRegions path").attr("transform", e.transform);
    d3.selectAll("svg.mapRegions circle").attr("transform", e.transform);
    // setZoomLevel(e.transform.k)
  }

  function zoomIn() {
    d3.select("svg.mapRegions").transition().call(zoom.scaleBy, 1.25);
  }

  function zoomOut() {
    d3.select("svg.mapRegions").transition().call(zoom.scaleBy, 0.75);
  }

  function center() {
    d3.select("svg.mapRegions")
      .transition()
      .call(zoom.translateTo, 0.5 * viewportWidth, 0.5 * viewportHeight);
  }

  function reset() {
    center();
    d3.select("svg.mapRegions")
      .call(zoom.translateTo, 0.5 * viewportWidth, 0.5 * viewportHeight)
      .call(zoom.scaleTo, zoomLevel);
  }

  initZoom();

  useEffect(() => {
    d3.select("svg.mapRegions").call(zoom.translateTo, 0.5 * viewportWidth, 0.5 * viewportHeight);
  }, [viewportHeight, viewportWidth]);

  useEffect(() => {
    if (countriesMap && data) {
      const irelandMergeSet = new Set([
        1322, 1323, 1324, 1325, 1326, 1327, 1328, 1329, 1330, 1331, 1332, 1333, 1334, 1335, 1336, 1337, 1338, 1339, 1340, 1341, 1342, 1343, 1344,
        1345, 1346, 1347,
      ]);

      // Show ireland greyed out and not selectable, irland regions are merged together

      const mergedIreland = topojson.merge(
        irelandMap,
        irelandMap.objects.IRL_adm1.geometries.filter(function (d) {
          return irelandMergeSet.has(d.properties.ID_1);
        })
      );

      const customColors = {
        range4: ["#ffb8d9", "#ee8ab4", "#dc588c", "#c70e63"],
        // range5: ["#331f37", "#68307c", "#a549b5", "#b67fbf", "#dfc7e3"],
        // range5: ["#29182D", "#942F45", "#FE455D", "#F36577", "#E88491"],
        range5: ["#dfc7e3", "#b67fbf", "#a549b5", "#68307c", "#331f37"],
        // range9: ["#ffb8d9", "#faa9cd", "#f499c0", "#ee8ab4", "#e97aa7", "#e26999", "#dc588c", "#d5467e", "#ce3071", "#c70e63"],
      };

      setMapRegions(
        choroplethRegions(
          data,
          {
            id: (d) => d.id,
            value: (d) => d[`Q${question}`],
            scale: d3.scaleQuantize,
            domain: [dataHighLow[0], dataHighLow[1]],
            range: customColors.range5,
            cities: cityData,
            projection: d3
              .geoAlbers()
              .center([0, 55.3])
              .rotate([4.4, 0])
              .parallels([60, 60])
              // .scale(7000)
              .scale(viewportHeight * 5)
              .translate([viewportWidth / 2, viewportHeight / 2]),
            title: (f, d) => {
              // Question has a £ based answer the others are % based

              // if (f && question == 1)
              //   return `
              // <div style="font-family: 'PhoenixSans-Medium',sans-serif;font-size: 1.2rem; line-height:1.6rem; color:#ff455d; margin-top:0.4rem;">${d["Region"]}</div>
              // <div style="font-family: 'PhoenixSans-Bold',sans-serif;font-size: 1.6rem; line-height:2rem; color:#331f37;">£${d["Q1"]}</div>
              // `;
              // else
              console.log(f);
              if ((f && question == "11b") || (f && question == "12a") || (f && question == "18a"))
                return `
                <div style="font-family: 'PhoenixSans-Medium',sans-serif;font-size: 1.2rem; line-height:1.6rem; color:#ff455d; margin-top:0.4rem;">${
                  d["Region"]
                }</div>
                <div style="font-family: 'PhoenixSans-Bold',sans-serif;font-size: 1.6rem; line-height:2rem; color:#331f37;">${Number(
                  d["Q" + question]
                ).toFixed(1)}</div>
                `;
              else if (f && question == "14c")
                return `
                <div style="font-family: 'PhoenixSans-Medium',sans-serif;font-size: 1.2rem; line-height:1.6rem; color:#ff455d; margin-top:0.4rem;">${
                  d["Region"]
                }</div>
                <div style="font-family: 'PhoenixSans-Bold',sans-serif;font-size: 1.6rem; line-height:2rem; color:#331f37;">£${Number(
                  d["Q" + question]
                ).toFixed(1)}k</div>
                `;
              else if ((f && question == "16a") || (f && question == "17b"))
                return `
                <div style="font-family: 'PhoenixSans-Medium',sans-serif;font-size: 1.2rem; line-height:1.6rem; color:#ff455d; margin-top:0.4rem;">${
                  d["Region"]
                }</div>
                <div style="font-family: 'PhoenixSans-Bold',sans-serif;font-size: 1.6rem; line-height:2rem; color:#331f37;">£${Number(
                  d["Q" + question]
                ).toFixed(2)}</div>
                `;
              else
                return `
                <div style="font-family: 'PhoenixSans-Medium',sans-serif;font-size: 1.2rem; line-height:1.6rem; color:#ff455d; margin-top:0.4rem;">${
                  d["Region"]
                }</div>
                <div style="font-family: 'PhoenixSans-Bold',sans-serif;font-size: 1.6rem; line-height:2rem; color:#331f37;">${
                  d["Q" + question]
                }%</div>
                `;
            },
            features: regions,
            features2: ireland,
            countries: countries,
            regions: regions,
            borders: countriesMesh,
            borders2: regionsMesh,
            borders3: mergedIreland,
            strokeWidth: 3,
            width: viewportWidth,
            height: viewportHeight,
            question: question,
          },
          loaded,
          question,
          navOpen
          // setCurrentLocation,
          // currentLocation
        )
      );
    }
    // }, [data, cityData, question, dataHighLow, navOpen, mapRegions]);
  }, [data, cityData, question, dataHighLow, navOpen, regions]);

  const currentQuestion = questionText.find((questionEl) => {
    return questionEl.Question === question;
  });

  const toggleNav = (e) => {
    if (viewportWidth < 768 && navOpen) {
      setShowInfo(false);
    }
    // else if (viewportWidth < 768 && !navOpen && e) {
    //   setShowInfo(false);
    // }
    setNavOpen(!navOpen);
  };
  // move nav off screen if toggled
  useEffect(() => {
    const nav = document.getElementById("side-panel");
    const mc = document.getElementById("map-container");
    const question = document.getElementsByClassName("question-display")[0];
    const mr = document.getElementsByClassName("mapRegions")[0];

    if (nav && mc && mr && !navOpen) {
      nav.style.left = "-253px";
      mc.style.width = "calc(100% + 253px)";
      if (question) question.style.left = "-227px";
      if (question) question.style.width = "100%";
      if (question) question.style.minWidth = "225px";
      // mr.style.right = "121px"
      mr.style.height = "100vh";
    } else if (nav && mc && mr && navOpen) {
      nav.style.left = "0";
      mc.style.width = "100%";
      if (question) question.style.left = "50px";
      if (question) question.style.width = "50%";
      // mr.style.right = "0px"
      mr.style.height = "100vh";
    }
  });

  useEffect(() => {
    if (viewportWidth < 768) {
      setNavOpen(false);
      // setShowInfo(false);
    } else {
      setNavOpen(true);
      // setShowInfo(true);
    }
  }, [viewportWidth]);

  useEffect(() => {
    if (viewportWidth < 768 && showInfo && navOpen) {
      // setNavOpen(false);
      setShowInfo(false);
    }
    // else {
    //   setShowInfo((prevState) => ({
    //     ...prevState
    //   }));
    //   // setShowInfo(true);
    // }
  }, [navOpen]);

  return (
    <>
      <div id="side-panel">
        <a href="https://www.thephoenixgroup.com/" target="_blank">
          <img src={mainLogo} alt="Phoenix Group" className="main-logo" />
        </a>
        <div className="sidepanel-questions">

          {/* QUESTION 1 */}

          <h3 className="sidepanel-question-heading">{questionText[0]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("1a");
              }}
              className={question == "1a" ? `question-button active` : `question-button`}
            >
              {questionText[0]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("1b");
              }}
              className={question == "1b" ? `question-button active` : `question-button`}
            >
              {questionText[1]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("1c");
              }}
              className={question == "1c" ? `question-button active` : `question-button`}
            >
              {questionText[2]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("1d");
              }}
              className={question == "1d" ? `question-button active` : `question-button`}
            >
              {questionText[3]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("1e");
              }}
              className={question == "1e" ? `question-button active` : `question-button`}
            >
              {questionText[4]?.Subtext}
            </button>
          </div>

          {/* QUESTION 2 */}

          <h3 className="sidepanel-question-heading">{questionText[5]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("2a");
              }}
              className={question == "2a" ? `question-button active` : `question-button`}
            >
              {questionText[5]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("2b");
              }}
              className={question == "2b" ? `question-button active` : `question-button`}
            >
              {questionText[6]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("2c");
              }}
              className={question == "2c" ? `question-button active` : `question-button`}
            >
              {questionText[7]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("2d");
              }}
              className={question == "2d" ? `question-button active` : `question-button`}
            >
              {questionText[8]?.Subtext}
            </button>
          </div>

          {/* QUESTION 3 */}

          <h3 className="sidepanel-question-heading">{questionText[9]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("3a");
              }}
              className={question == "3a" ? `question-button active` : `question-button`}
            >
              {questionText[9]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("3b");
              }}
              className={question == "3b" ? `question-button active` : `question-button`}
            >
              {questionText[10]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("3c");
              }}
              className={question == "3c" ? `question-button active` : `question-button`}
            >
              {questionText[11]?.Subtext}
            </button>
          </div>

          {/* QUESTION 4 */}

          <h3 className="sidepanel-question-heading">{questionText[12]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("4a");
              }}
              className={question == "4a" ? `question-button active` : `question-button`}
            >
              {questionText[12]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("4b");
              }}
              className={question == "4b" ? `question-button active` : `question-button`}
            >
              {questionText[13]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("4c");
              }}
              className={question == "4c" ? `question-button active` : `question-button`}
            >
              {questionText[14]?.Subtext}
            </button>
          </div>

          {/* QUESTION 5 */}

          <h3 className="sidepanel-question-heading">{questionText[15]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("5a");
              }}
              className={question == "5a" ? `question-button active` : `question-button`}
            >
              {questionText[15]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("5b");
              }}
              className={question == "5b" ? `question-button active` : `question-button`}
            >
              {questionText[16]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("5c");
              }}
              className={question == "5c" ? `question-button active` : `question-button`}
            >
              {questionText[17]?.Subtext}
            </button>
          </div>

          {/* QUESTION 6 */}

          <h3 className="sidepanel-question-heading">{questionText[18]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("6a");
              }}
              className={question == "6a" ? `question-button active` : `question-button`}
            >
              {questionText[18]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("6b");
              }}
              className={question == "6b" ? `question-button active` : `question-button`}
            >
              {questionText[19]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("6c");
              }}
              className={question == "6c" ? `question-button active` : `question-button`}
            >
              {questionText[20]?.Subtext}
            </button>
          </div>

          {/* QUESTION 7 */}

          <h3 className="sidepanel-question-heading">{questionText[21]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("7a");
              }}
              className={question == "7a" ? `question-button active` : `question-button`}
            >
              {questionText[21]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("7b");
              }}
              className={question == "7b" ? `question-button active` : `question-button`}
            >
              {questionText[22]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("7c");
              }}
              className={question == "7c" ? `question-button active` : `question-button`}
            >
              {questionText[23]?.Subtext}
            </button>
          </div>

          {/* QUESTION 8 */}

          <h3 className="sidepanel-question-heading">{questionText[24]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("8a");
              }}
              className={question == "8a" ? `question-button active` : `question-button`}
            >
              {questionText[24]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("8b");
              }}
              className={question == "8b" ? `question-button active` : `question-button`}
            >
              {questionText[25]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("8c");
              }}
              className={question == "8c" ? `question-button active` : `question-button`}
            >
              {questionText[26]?.Subtext}
            </button>
          </div>

          {/* QUESTION 9 */}

          <h3 className="sidepanel-question-heading">{questionText[27]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("9a");
              }}
              className={question == "9a" ? `question-button active` : `question-button`}
            >
              {questionText[27]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("9b");
              }}
              className={question == "9b" ? `question-button active` : `question-button`}
            >
              {questionText[28]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("9c");
              }}
              className={question == "9c" ? `question-button active` : `question-button`}
            >
              {questionText[29]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("9d");
              }}
              className={question == "9d" ? `question-button active` : `question-button`}
            >
              {questionText[30]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("9e");
              }}
              className={question == "9e" ? `question-button active` : `question-button`}
            >
              {questionText[31]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("9f");
              }}
              className={question == "9f" ? `question-button active` : `question-button`}
            >
              {questionText[32]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("9g");
              }}
              className={question == "9g" ? `question-button active` : `question-button`}
            >
              {questionText[33]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("9h");
              }}
              className={question == "9h" ? `question-button active` : `question-button`}
            >
              {questionText[34]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("9i");
              }}
              className={question == "9i" ? `question-button active` : `question-button`}
            >
              {questionText[35]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("9j");
              }}
              className={question == "9j" ? `question-button active` : `question-button`}
            >
              {questionText[36]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("9k");
              }}
              className={question == "9k" ? `question-button active` : `question-button`}
            >
              {questionText[37]?.Subtext}
            </button>
          </div>

          {/* QUESTION 10 */}

          <h3 className="sidepanel-question-heading">{questionText[38]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("10a");
              }}
              className={question == "10a" ? `question-button active` : `question-button`}
            >
              {questionText[38]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("10b");
              }}
              className={question == "10b" ? `question-button active` : `question-button`}
            >
              {questionText[39]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("10c");
              }}
              className={question == "10c" ? `question-button active` : `question-button`}
            >
              {questionText[40]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("10d");
              }}
              className={question == "10d" ? `question-button active` : `question-button`}
            >
              {questionText[41]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("10e");
              }}
              className={question == "10e" ? `question-button active` : `question-button`}
            >
              {questionText[42]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("10f");
              }}
              className={question == "10f" ? `question-button active` : `question-button`}
            >
              {questionText[43]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("10g");
              }}
              className={question == "10g" ? `question-button active` : `question-button`}
            >
              {questionText[44]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("10h");
              }}
              className={question == "10h" ? `question-button active` : `question-button`}
            >
              {questionText[45]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("10i");
              }}
              className={question == "10i" ? `question-button active` : `question-button`}
            >
              {questionText[46]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("10j");
              }}
              className={question == "10j" ? `question-button active` : `question-button`}
            >
              {questionText[47]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("10k");
              }}
              className={question == "10k" ? `question-button active` : `question-button`}
            >
              {questionText[48]?.Subtext}
            </button>
          </div>

          {/* QUESTION 11 */}

          <h3 className="sidepanel-question-heading">{questionText[49]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("11a");
              }}
              className={question == "11a" ? `question-button active` : `question-button`}
            >
              {questionText[49]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("11b");
              }}
              className={question == "11b" ? `question-button active` : `question-button`}
            >
              {questionText[50]?.Subtext}
            </button>
          </div>

          {/* QUESTION 12 */}

          <h3 className="sidepanel-question-heading">{questionText[51]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("12a");
              }}
              className={question == "12a" ? `question-button active` : `question-button`}
            >
              {questionText[51]?.Subtext}
            </button>
          </div>

          {/* QUESTION 13 */}

          <h3 className="sidepanel-question-heading">{questionText[52]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("13a");
              }}
              className={question == "13a" ? `question-button active` : `question-button`}
            >
              {questionText[52]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("13b");
              }}
              className={question == "13b" ? `question-button active` : `question-button`}
            >
              {questionText[53]?.Subtext}
            </button>
          </div>

          {/* QUESTION 14 */}

          <h3 className="sidepanel-question-heading">{questionText[54]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("14a");
              }}
              className={question == "14a" ? `question-button active` : `question-button`}
            >
              {questionText[54]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("14b");
              }}
              className={question == "14b" ? `question-button active` : `question-button`}
            >
              {questionText[55]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("14c");
              }}
              className={question == "14c" ? `question-button active` : `question-button`}
            >
              {questionText[56]?.Subtext}
            </button>
          </div>

          {/* QUESTION 15 */}

          <h3 className="sidepanel-question-heading">{questionText[57]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("15a");
              }}
              className={question == "15a" ? `question-button active` : `question-button`}
            >
              {questionText[57]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("15b");
              }}
              className={question == "15b" ? `question-button active` : `question-button`}
            >
              {questionText[58]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("15c");
              }}
              className={question == "15c" ? `question-button active` : `question-button`}
            >
              {questionText[59]?.Subtext}
            </button>
          </div>

          {/* QUESTION 16 */}

          <h3 className="sidepanel-question-heading">{questionText[60]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("16a");
              }}
              className={question == "16a" ? `question-button active` : `question-button`}
            >
              {questionText[60]?.Subtext}
            </button>
          </div>

          {/* QUESTION 17 */}

          <h3 className="sidepanel-question-heading">{questionText[61]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("17a");
              }}
              className={question == "17a" ? `question-button active` : `question-button`}
            >
              {questionText[61]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("17b");
              }}
              className={question == "17b" ? `question-button active` : `question-button`}
            >
              {questionText[62]?.Subtext}
            </button>
          </div>

          {/* QUESTION 18 */}

          <h3 className="sidepanel-question-heading">{questionText[63]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("18a");
              }}
              className={question == "18a" ? `question-button active` : `question-button`}
            >
              {questionText[63]?.Subtext}
            </button>
          </div>

          {/* QUESTION 19 */}

          <h3 className="sidepanel-question-heading">{questionText[64]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("19a");
              }}
              className={question == "19a" ? `question-button active` : `question-button`}
            >
              {questionText[64]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("19b");
              }}
              className={question == "19b" ? `question-button active` : `question-button`}
            >
              {questionText[65]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("19c");
              }}
              className={question == "19c" ? `question-button active` : `question-button`}
            >
              {questionText[66]?.Subtext}
            </button>
          </div>

          {/* QUESTION 20 */}

          <h3 className="sidepanel-question-heading">{questionText[67]?.Text}</h3>
          <div className="question-button-container">
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("20a");
              }}
              className={question == "20a" ? `question-button active` : `question-button`}
            >
              {questionText[67]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("20b");
              }}
              className={question == "20b" ? `question-button active` : `question-button`}
            >
              {questionText[68]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("20c");
              }}
              className={question == "20c" ? `question-button active` : `question-button`}
            >
              {questionText[69]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("20d");
              }}
              className={question == "20d" ? `question-button active` : `question-button`}
            >
              {questionText[70]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("20e");
              }}
              className={question == "20e" ? `question-button active` : `question-button`}
            >
              {questionText[71]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("20f");
              }}
              className={question == "20f" ? `question-button active` : `question-button`}
            >
              {questionText[72]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("20g");
              }}
              className={question == "20g" ? `question-button active` : `question-button`}
            >
              {questionText[73]?.Subtext}
            </button>
            <button
              onClick={() => {
                if (viewportWidth < 768) toggleNav();
                setQuestion("20h");
              }}
              className={question == "20h" ? `question-button active` : `question-button`}
            >
              {questionText[74]?.Subtext}
            </button>
          </div>
        </div>
        <div id="nav-toggle" onClick={toggleNav}>
          Questions
        </div>
      </div>
      <div id="map-holder">
        {/* {(!loaded || data.length == 0) && <Spinner />} */}
        {data.length == 0 && <Spinner />}
        {/* {data.length > 0 && ( */}
        <Fragment>
          <ZoomControls zoomIn={zoomIn} zoomOut={zoomOut} reset={reset} showInfo={showInfo} setShowInfo={setShowInfo} />
          <a
            href="https://www.thephoenixgroup.com/"
            target="_blank"
            style={navOpen ? { pointerEvents: "none", userSelect: "none" } : { userSelect: "none" }}
          >
            <img src={darkLogo} className="logo-dark" />
          </a>
          {/* {showInfo && ( */}
          <div className={showInfo ? "question-display fade-in" : "question-display fade-out"}>
            {/* <div className={'question-display'}> */}
            <div>
              <h2 className="question-heading">{currentQuestion?.Text}</h2>
              <h3 className="question-subheading">{currentQuestion?.Subtext}</h3>
              {question !== "11b" && question !== "12a" && question !== "14c" && question !== "16a" && question !== "17b" && question !== "18a" && (
                <h3 className="question-avg">National Average: {currentQuestion?.["National Average"]}%</h3>
              )}
              {(question == "11b" || question == "12a" || question == "18a") && (
                <h3 className="question-avg">National Average: {Number(currentQuestion?.["National Average"]).toFixed(1)}</h3>
              )}
              {question == "14c" && <h3 className="question-avg">National Average: £{Number(currentQuestion?.["National Average"]).toFixed(1)}k</h3>}
              {(question == "16a" || question == "17b") && (
                <h3 className="question-avg">National Average: £{Number(currentQuestion?.["National Average"]).toFixed(2)}</h3>
              )}
            </div>
          </div>
          {/* )}  */}
          <div ref={svgRegions} id="map-container" style={{ backgroundColor: "#fff4ec" }} />
          {/* <div ref={svgRegions} id="map-container" style={{ backgroundColor: "#ffffff" }} /> */}
          <div id="tooltip" className={navOpen ? "open" : "closed"}></div>
        </Fragment>
        {/* )} */}
      </div>
    </>
  );
};

export default MapUK;
