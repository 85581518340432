const ZoomOut = (props) => (
    <div className="zoom-button">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46" onClick={props.clickAction}>
        <rect className="cls-2" width="46" height="46" rx="5" ry="5" />
        <rect className="cls-1" x="9.58" y="21.08" width="26.83" height="3.83" rx="1.92" ry="1.92" />
      </svg>
    </div>
  );

export default ZoomOut;
